import TagManager from 'react-gtm-module';
import { DefaultSeo } from 'next-seo';
import SEO from 'next-seo.config';
import { useEffect, useState } from 'react';
import 'styles/globals.scss';
import { wrapper } from '../store';
import NextNProgress from 'nextjs-progressbar';
import AppLayout from 'layouts/AppLayout';
import { useStore } from 'react-redux';
import auth from '../utils/auth';
import { useRouter } from 'next/router';

const MyApp = ({ Component, pageProps }) => {
    const router = useRouter();
    const isEmbedded = router.pathname.startsWith('/embed');

    useEffect(() => {
        TagManager.initialize({
            gtmId: 'GTM-W68FPWM',
        });
    }, []);

    const store = useStore();

    const [isAuthFinished, setIsAuthFinished] = useState(false);
    useEffect(() => {
        // Initializes auth logic, including interceptors when the app is first rendered
        (async () => {
            await auth(store);
            setIsAuthFinished(true);
        })();
    }, []);

    // check if JS turned off
    const isServer = typeof window === 'undefined';

    if (isEmbedded) {
        return isAuthFinished || isServer ? <Component {...pageProps} /> : null;
    }

    return (
        <>
            <DefaultSeo {...SEO} />
            <NextNProgress color="#3e20ff" />
            <AppLayout>
                {(isAuthFinished || isServer) && <Component {...pageProps} />}
            </AppLayout>
        </>
    );
};

export default wrapper.withRedux(MyApp);
